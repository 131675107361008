<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <div id="editModal" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="">
          <b-card-code title="Edit Room">
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="12">
                  <Translations @lang="handleLanguage($event)" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="name">Name</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Name"
                          rules="required"
                      >
                        <b-form-input
                            id="name"
                            v-model="UpdatedRoom.name"
                            name="name"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="icon">Icon</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Icon"
                          rules=""
                      >
                        <vue-dropzone
                            id="icon"
                            ref="roomImageRef"
                            name="icon"
                            :options="dropzoneOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="anchor_icon">Anchor Icon</label>
                      <validation-provider
                          #default="{ errors }"
                          name="anchor_icon"
                          rules=""
                      >
                        <vue-dropzone
                            id="anchor_icon"
                            ref="roomAnchorImageRef"
                            name="anchor_icon"
                            :options="dropzoneOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="validationForm">
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>
            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import {codeMultiple, codeBasic} from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Translations from '../../../shared/translations/Translations.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import roomsService from '../../../services/roomsService'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
    Translations
  },
  props: ['item'],
  data() {
    return {
      required,
      codeMultiple,
      UpdatedRoom: {
        title: '',
        description: '',
        sort: ''
      },
      codeBasic,
      snowOption: {
        theme: 'snow',
      },
      newFile: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictRemoveFile: 'Remove',
        maxFiles: 1,
        init: function() {
          this.on("maxfilesexceeded", function(file) {
            this.removeAllFiles();
            this.addFile(file);
            this.newFile = true;
          });
        },
      },
    }
  },
  mounted() {
    this.$watch(() => this.item, item => {
      console.log(this.item)
      this.UpdatedRoom= {
        name: item.title,
      }
      this.$refs.roomImageRef.removeAllFiles()
      this.$refs.roomAnchorImageRef.removeAllFiles()
      if(this.newFile === false){
        this.$refs.roomImageRef.manuallyAddFile(item.icon[0].file, item.icon[0].url)
        this.$refs.roomAnchorImageRef.manuallyAddFile(item.anchorIcon[0].file, item.anchorIcon[0].url)
      }
    })
  },
  methods: {
    handleLanguage(lang) {
      roomsService.show(this.item.id, { lang })
          .then(response => {
            this.UpdatedRoom= {
              id: response.data.data.id,
              name: response.data.data.title,
            }
          })
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              if(this.$refs.roomImageRef.getAcceptedFiles().length > 0){
                this.UpdatedRoom.icon = this.$refs.roomImageRef.getAcceptedFiles()[0]
              }
              if(this.$refs.roomAnchorImageRef.getAcceptedFiles().length > 0){
                this.UpdatedRoom.anchor_icon = this.$refs.roomAnchorImageRef.getAcceptedFiles()[0]
              }
              roomsService.put(this.item.id, this.UpdatedRoom).then(response => {
                if (response && response.data.code === 200) {
                  this.$emit('refresh', 'true')
                  $('#editModal').modal('hide')
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Room Is Updated Successfully !',
                    },
                  })
                } else{
                  $('#editModal').modal('hide')
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'CheckIcon',
                      variant: 'danger',
                      text: response.data.message,
                    },
                  })
                }
                this.loading = false
              })

            }
          }).catch(function (error) {

        $('#editModal').modal('hide')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'Error',
            text: 'Error !',
          },
        })
        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}

#editModal svg {
  filter: invert(69%) sepia(14%) saturate(4763%) hue-rotate(3deg) brightness(100%) contrast(43%) !important;
}
</style>
