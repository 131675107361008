import AbstractService from '@/services/abstractService'

class RoomsService extends AbstractService {
  endpoint = 'rooms'

  put(id, data, params = null) {
    // define append form
    const formData = new FormData()

    for (const key in data) {
      // if ((key === 'icon' && data[key] === undefined) || (key === 'anchor_icon' && data[key] === undefined)) {
      //   continue
      // }
      if (key === 'icon' && data[key] === undefined)  {
        continue
      }
      if (key === 'anchor_icon' && data[key] === undefined) {
        continue
      }
      formData.append(key, data[key])
    }
    return this.http.put(`${this.endpoint}/${id}`, formData, params)
  }
  changeStatus(id, data, params = null) {
    // define append form
    const formData = new FormData()

    for (const key in data) {
      formData.append(key, data[key])
    }
    return this.http.put(`change-room-status/${id}`, formData, params)
  }


  assignRoomToUser(data) {
    // define append form
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, data[key])
    }
    return this.http.post('user-rooms', formData)
  }
  updateRoomToUser(data, params = null) {

    // define append form
    const formData = new FormData()

    for (const key in data) {
      formData.append(key, data[key])
    }
    return this.http.put(`user-rooms/${data.id}`, formData, params)
  }

  /**
   * get all resource data
   */
  availableRooms(id) {
    return this.http.get(`available-user-rooms/${id}`)
  }


}




const Service = new RoomsService()

export default Service
