<template>
  <!-- search input -->
  <b-form-group>
    <label for="lang">Languages</label>
    <validation-provider
        #default="{ errors }"
        name="lang"
        rules="required"
    >
      <v-select
          v-model="lang"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="langs"
          :reduce="name => name.code"
          label="name"
          name="lang"
          required
          @input="catchLanguage($event)"
      />
      <input hidden :value="lang" id="lang"/>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
  <!-- table -->

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BFormGroup, BCol } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { codeBasic } from './code'
import languagesService from '@/services/languagesService'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    BFormGroup,
    required,
    vSelect,
    BCol,
  },
  data() {
    return {
      lang: '',
      langs: [],
      codeBasic,
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    const object = this
    $('#editModal')
        .on('show.bs.modal', function (e) {
          object.lang = 'en'
        })
    this.getLanguages()
  },
  methods: {
    getLanguages() {
      languagesService.index()
          .then(response => {
            this.langs = response.data.data
            this.lang = 'en'
          })
    },
    catchLanguage(code) {
      this.$emit('lang', code)
    },
  },
}
</script>
<style lang="scss">

.vgt-responsive {
  width: 100%;
  overflow-x: inherit !important;
  position: relative;
}
</style>
