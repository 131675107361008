import AbstractService from '@/services/abstractService'

class LanguagesService extends AbstractService {
  endpoint = 'languages'

  changeStatus(id) {
    return this.http.get(`${this.endpoint}/changeStatus/${id}`)
  }
}

const Service = new LanguagesService()

export default Service
